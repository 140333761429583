

import {useDispatch, useSelector} from "react-redux";
import {setLastName} from "../../redux/slices/formDataSlice";


const LastNameInput = () => {
    const dispatch = useDispatch();
    const {lastName} = useSelector((state) => state.formData);
    return (
        <div className="col-md-6 ">
            <input
                minLength={2}
                value={lastName}
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Last Name"
                required
                onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/\d/g, "").toUpperCase();
                    dispatch(setLastName(value));
                    e.target.value = value;
                }}
            />
        </div>
    );
};


export default LastNameInput;
