

const HeroSection = () => {
    return (
        <section id="hero" className="hero section">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="hero-content" data-aos="fade-up" data-aos-delay="200">
                            <div className="company-badge mb-4">
                                <i className="bi bi-gear-fill me-2"></i>
                                Working for your success
                            </div>

                            <h1 className="mb-4">
                                Welcome<br/>
                                to JunoSup<br/>
                                <span className="accent-text">24/7 B2B support</span>
                            </h1>

                            <p className="mb-4 mb-md-5">
                                We specialize in providing expertly trained support professionals tailored to meet the
                                unique needs of your business.
                            </p>

                            <div className="hero-buttons">
                                <a href="/#contact" className="btn btn-primary me-0 me-sm-2 mx-1">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="hero-image" data-aos="zoom-out" data-aos-delay="300">
                            <img src="static/img/illustration-1.webp" alt="Hero Image" className="img-fluid"/>

                            <div className="customers-badge">
                                <div className="customer-avatars">
                                    <img src="static/img/avatar-1.webp" alt="Customer 1" className="avatar"/>
                                    <img src="static/img/avatar-2.webp" alt="Customer 2" className="avatar"/>
                                    <img src="static/img/avatar-3.webp" alt="Customer 3" className="avatar"/>
                                    <img src="static/img/avatar-4.webp" alt="Customer 4" className="avatar"/>
                                    <img src="static/img/avatar-5.webp" alt="Customer 5" className="avatar"/>
                                    <span className="avatar more">+</span>
                                </div>
                                <p className="mb-0 mt-2">3.000+ highly skilled professionals dedicated to delivering
                                    exceptional technical support.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row stats-row gy-4 mt-5" data-aos="fade-up" data-aos-delay="500">
                    <div className="col-lg-3 col-md-6">
                        <div className="stat-item">
                            <div className="stat-icon">
                                <i className="bi bi-trophy"></i>
                            </div>
                            <div className="stat-content">
                                <h4>Best Support</h4>
                                <p className="mb-0">By TechRadar</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="stat-item">
                            <div className="stat-icon">
                                <i className="bi bi-briefcase"></i>
                            </div>
                            <div className="stat-content">
                                <h4>6.5K</h4>
                                <p className="mb-0">Corporate Partners</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="stat-item">
                            <div className="stat-icon">
                                <i className="bi bi-graph-up"></i>
                            </div>
                            <div className="stat-content">
                                <h4>180M</h4>
                                <p className="mb-0">Successfully Worked Tickets</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="stat-item">
                            <div className="stat-icon">
                                <i className="bi bi-award"></i>
                            </div>
                            <div className="stat-content">
                                <h4>20+</h4>
                                <p className="mb-0">Speaking Languages</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};


export default HeroSection;
