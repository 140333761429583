

const AboutSection = () => {
    return (
        <section id="about" className="about section">

            <div className="container" data-aos="fade-up" data-aos-delay="100">

                <div className="row gy-4 align-items-center justify-content-between">

                    <div className="col-xl-5" data-aos="fade-up" data-aos-delay="200">
                        <span className="about-meta">MORE ABOUT US</span>
                        <h2 className="about-title">Our Mission</h2>
                        <p className="about-description">Our mission is simple: to provide reliable, round-the-clock
                            technical support that empowers businesses to focus on what they do best—innovating,
                            creating, and growing. By delivering customized solutions and unmatched expertise, we aim to
                            become an indispensable part of your team.</p>

                        <div className="row feature-list-wrapper">
                            <div className="col-md-6">
                                <ul className="feature-list">
                                    <li><i className="bi bi-check-circle-fill"></i>Tailored Solutions</li>
                                    <li><i className="bi bi-check-circle-fill"></i>Problem Prevention</li>
                                    <li><i className="bi bi-check-circle-fill"></i>24/7 Availability</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="feature-list">
                                    <li><i className="bi bi-check-circle-fill"></i>Expert Training</li>
                                    <li><i className="bi bi-check-circle-fill"></i>Data-Driven Insights</li>
                                    <li><i className="bi bi-check-circle-fill"></i>Scalable Teams</li>
                                </ul>
                            </div>
                        </div>

                        <div className="info-wrapper">
                            <div className="row gy-4">
                                <div className="col-lg-5">
                                    <div className="profile d-flex align-items-center gap-3">
                                        <img src="static/img/ceo.jpg" alt="CEO Profile" className="profile-image"/>
                                        <div>
                                            <h4 className="profile-name">Mark Fournier</h4>
                                            <p className="profile-position">CEO &amp; Founder</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="contact-info d-flex align-items-center gap-2">
                                        <i className="bi bi-telephone-fill"></i>
                                        <div>
                                            <p className="contact-label">Call us anytime</p>
                                            <p className="contact-number">800-516-7989</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="image-wrapper">
                            <div className="images position-relative" data-aos="zoom-out" data-aos-delay="400">
                                <img src="static/img/about-5.webp" alt="Business Meeting"
                                     className="img-fluid main-image rounded-4"/>
                                <img src="static/img/about-2.webp" alt="Team Discussion"
                                     className="img-fluid small-image rounded-4"/>
                            </div>
                            <div className="experience-badge floating">
                                <h3>15+ <span>Years</span></h3>
                                <p>Of experience in business service</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
};


export default AboutSection;
