

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const uploadApi = createApi({
    reducerPath: 'uploadApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
    }),
    endpoints: (builder) => ({
        upload: builder.mutation({
            query: (params) => ({
                url: `/upload`,
                method: 'POST',
                body: params
            }),
        }),
    }),
})


export const { useUploadMutation } = uploadApi;
