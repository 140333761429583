

import {Link} from "react-router-dom";


const Header = () => {
    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div
                className="header-container container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                <Link to="/" className="logo d-flex align-items-center me-auto me-xl-0">
                    <h1 className="sitename">JunoSup</h1>
                </Link>

                <nav id="navmenu" className="navmenu">
                    <ul>
                        <li><a href="/#hero">Home</a></li>
                        <li><a href="/#about">About</a></li>
                        <li><a href="/#features">Features</a></li>
                        <li><a href="/#contact">Contact</a></li>
                    </ul>
                    <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                </nav>

                <Link className="btn-getstarted" to="/jobs">
                    Jobs
                </Link>

            </div>
        </header>
    );
};


export default Header;
