

const Testimonials = () => {
    return (
        <section id="testimonials" className="testimonials section light-background">

            <div className="container section-title" data-aos="fade-up">
                <h2>Testimonials</h2>
                <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
            </div>

            <div className="container">

                <div className="row g-5">

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="testimonial-item">
                            <img src="static/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt=""/>
                            <h3>Michael T.</h3>
                            <h4>Senior Operations Manager, Uber</h4>
                            <div className="stars">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                <span>Partnering with JunoSup has been a game-changer for us. Their team seamlessly integrates with ours, ensuring our customers receive top-notch support 24/7. We couldn't be happier with their professionalism and expertise.</span>
                                <i className="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="testimonial-item">
                            <img src="static/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt=""/>
                            <h3>Sara Wilsson</h3>
                            <h4>Designer, TruckLife</h4>
                            <div className="stars">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                <span>The team at JunoSup has been a tremendous asset. Their ability to scale quickly during peak times has been invaluable, and their dedication to quality is unmatched.</span>
                                <i className="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="testimonial-item">
                            <img src="static/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt=""/>
                            <h3>Rachel M.</h3>
                            <h4>Director of Operations, Grubhub</h4>
                            <div className="stars">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                <span>We’ve been impressed with professionalism and responsiveness. Their support ensures our customers always get the help they need, no matter the time of day.</span>
                                <i className="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="testimonial-item">
                            <img src="static/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt=""/>
                            <h3>David W.</h3>
                            <h4>CTO, TechSpark</h4>
                            <div className="stars">
                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                className="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i className="bi bi-quote quote-icon-left"></i>
                                <span>JunoSup helped us optimize our support processes, significantly improving response times. Their training program ensures that their staff knows our systems inside out, making them an extension of our own team.</span>
                                <i className="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
};


export default Testimonials;
