

import {useDispatch, useSelector} from "react-redux";
import {setFirstName} from "../../redux/slices/formDataSlice";


const FirstNameInput = () => {
    const dispatch = useDispatch();
    const {firstName} = useSelector((state) => state.formData);
    return (
        <div className="col-md-6">
            <input
                minLength={2}
                value={firstName}
                type="text"
                name="firstName"
                className="form-control"
                placeholder="First Name"
                required
                onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/\d/g, "").toUpperCase();
                    dispatch(setFirstName(value));
                    e.target.value = value;
                }}
            />
        </div>
    );
};


export default FirstNameInput;
