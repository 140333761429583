

import {useDispatch} from "react-redux";
import {setBackDl} from "../../redux/slices/formDataSlice";


const DlBackInput = () => {
    const dispatch = useDispatch();
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            const base64 = reader.result.split(",")[1];
            dispatch(setBackDl(base64));
        };

        reader.readAsDataURL(file);
    };
    return (
        <div className="col-md-6 text-center">
            <label htmlFor="backDl" className="form-label mb-3 mt-2">Driver License (Back)</label>
            <input
                accept={"image/*"}
                id="backDl"
                type="file"
                className="form-control"
                name="backDl"
                placeholder="Driver License (Back)"
                required
                onChange={handleFileChange}
            />
        </div>
    );
};


export default DlBackInput;
