

const NotFoundPage = () => {
    return (
        <h1>
            Not found.
        </h1>
    );
};


export default NotFoundPage;
