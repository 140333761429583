

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="container copyright text-center mt-4">
                <p>© <span>Copyright</span> <strong className="px-1 sitename">JunoSup</strong>
                    <span>All Rights Reserved</span></p>
            </div>
        </footer>
    );
};


export default Footer;
