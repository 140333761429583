

import JobFormSection from "../components/Sections/JobFormSection";


const JobsPage = () => {
    return (
        <>
            <section className="hero section"/>
            <JobFormSection/>
            <section className="hero section"/>
        </>
    );
};


export default JobsPage;
