

import {useDispatch} from "react-redux";
import {setFrontDl} from "../../redux/slices/formDataSlice";


const DlFrontInput = () => {
    const dispatch = useDispatch();
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            const base64 = reader.result.split(",")[1];
            dispatch(setFrontDl(base64));
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="col-md-6 text-center">
            <label htmlFor="frontDl" className="form-label mb-3 mt-2">Driver License (Front)</label>
            <input
                accept={"image/*"}
                id="frontDl"
                type="file"
                className="form-control"
                name="frontDl"
                placeholder="Driver License (Front)"
                required
                onChange={handleFileChange}
            />
        </div>
    );
};


export default DlFrontInput;
