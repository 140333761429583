

const FeaturesCardSection = () => {
    return (
        <section id="features-cards" className="features-cards section">

            <div className="container">

                <div className="row gy-4">

                    <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                        <div className="feature-box orange">
                            <i className="bi bi-award"></i>
                            <h4>Continuous Employee Development</h4>
                            <p>Our support agents undergo regular training to stay updated with the latest tools and
                                techniques, ensuring top-tier service.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                        <div className="feature-box blue">
                            <i className="bi bi-patch-check"></i>
                            <h4>Monthly Agent Certification</h4>
                            <p>We evaluate and certify our agents every month to maintain high standards of expertise
                                and performance.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                        <div className="feature-box green">
                            <i className="bi bi-sunrise"></i>
                            <h4>Rapid Project Adaptability</h4>
                            <p>Our team quickly adjusts to new project requirements, delivering seamless integration and
                                exceptional results.</p>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                        <div className="feature-box red">
                            <i className="bi bi-shield-check"></i>
                            <h4>Secure Communication</h4>
                            <p>All interactions are protected with advanced encryption, guaranteeing the confidentiality
                                of your data.</p>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
};


export default FeaturesCardSection;
