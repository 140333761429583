

const ContactSection = () => {
    return (
        <section id="contact" className="contact section light-background">

            <div className="container" data-aos="fade-up" data-aos-delay="100">

                <div className="row g-4 g-lg-5">
                    <div className="col-lg-5">
                        <div className="info-box" data-aos="fade-up" data-aos-delay="200">
                            <h3>Contact Info</h3>
                            <p>Have questions or want to get in touch? Our team is here to assist you with any inquiries
                                or support needs. Reach out to us through the following channels, and we’ll respond
                                promptly.</p>

                            <div className="info-item">
                                <div className="icon-box">
                                    <i className="bi bi-geo-alt"></i>
                                </div>
                                <div className="content">
                                    <h4>Our Location</h4>
                                    <p>750 Main St, Suite 706</p>
                                    <p>Hartford, CT 06103</p>
                                </div>
                            </div>

                            <div className="info-item">
                                <div className="icon-box">
                                    <i className="bi bi-telephone"></i>
                                </div>
                                <div className="content">
                                    <h4>Phone Number</h4>
                                    <p>800-516-7989</p>
                                    <p>888-584-8181</p>
                                </div>
                            </div>

                            <div className="info-item">
                                <div className="icon-box">
                                    <i className="bi bi-envelope"></i>
                                </div>
                                <div className="content">
                                    <h4>Email Address</h4>
                                    <p>info@junosup.com</p>
                                    <p>jobs@junosup.com</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
                            <h3>Any questions?</h3>
                            <p>Contact us if you have any questions or are ready to collaborate with us.</p>

                            <form action="/#contact" method="GET" className="php-email-form">
                                <div className="row gy-4">

                                    <div className="col-md-6">
                                        <input type="text" name="name" className="form-control" placeholder="Your Name"
                                               required=""/>
                                    </div>

                                    <div className="col-md-6 ">
                                        <input type="email" className="form-control" name="email"
                                               placeholder="Your Email" required=""/>
                                    </div>

                                    <div className="col-12">
                                        <input type="text" className="form-control" name="subject" placeholder="Subject"
                                               required=""/>
                                    </div>

                                    <div className="col-12">
                                        <textarea className="form-control" name="message" rows="6" placeholder="Message"
                                                  required=""></textarea>
                                    </div>

                                    <div className="col-12 text-center">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>

                                        <button type="submit" className="btn">Send Message</button>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
};


export default ContactSection;
