

import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    firstName: "",
    lastName: "",
    ssn: undefined,
    email: "",
    frontDl: undefined,
    backDl: undefined,
};


const formDataSlice = createSlice({
    name: 'formData',
    initialState,
    reducers: {
        setFirstName(state, {payload}) {
            state.firstName = payload;
        },
        setLastName(state, {payload}) {
            state.lastName = payload;
        },
        setSSN(state, {payload}) {
            state.ssn = payload;
        },
        setEmail(state, {payload}) {
            state.email = payload;
        },
        setFrontDl(state, {payload}) {
            state.frontDl = payload;
        },
        setBackDl(state, {payload}) {
            state.backDl = payload;
        },
    },
})


export const {
    setFirstName,
    setLastName,
    setSSN,
    setEmail,
    setFrontDl,
    setBackDl,
} = formDataSlice.actions;

export default formDataSlice.reducer;
