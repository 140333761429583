

import {useDispatch, useSelector} from "react-redux";
import {setSSN} from "../../redux/slices/formDataSlice";


const SsnInput = () => {
    const dispatch = useDispatch();
    const {ssn} = useSelector((state) => state.formData);
    return (
        <div className="col-md-6 ">
            <input
                value={ssn}
                pattern="\d\d\d-\d\d-\d\d\d\d"
                minLength="11"
                maxLength="11"
                type="text" className="form-control"
                name="ssn" placeholder="Social Security Number"
                required
                onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/\D/g, '');
                    if (value.length > 3) value = value.slice(0, 3) + "-" + value.slice(3);
                    if (value.length > 6) value = value.slice(0, 6) + "-" + value.slice(6, 10);
                    value = value.slice(0, 11);
                    dispatch(setSSN(value));
                    e.target.value = value;
                }}
            />
        </div>
    );
};


export default SsnInput;
