

import {Link} from "react-router-dom";


const WantToWorkSection = () => {
    return (
        <section id="call-to-action-2" className="call-to-action-2 section dark-background">

            <div className="container">
                <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                    <div className="col-xl-10">
                        <div className="text-center">
                            <h3>Want to Work at JunoSup?</h3>
                            <p>We’re always looking for talented individuals who are passionate about providing
                                exceptional customer support and eager to grow in a dynamic, fast-paced environment.
                                Join our team and be part of a company that values continuous learning, innovation, and
                                customer satisfaction.</p>
                            <Link className="cta-btn" to="/jobs">Jobs</Link>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};


export default WantToWorkSection;
