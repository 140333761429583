

const FeaturesSection = () => {
    return (
        <section id="features" className="features section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Features</h2>
                <p>Learn more about our features</p>
            </div>

            <div className="container">

                <div className="d-flex justify-content-center">

                    <ul className="nav nav-tabs" data-aos="fade-up" data-aos-delay="100">

                        <li className="nav-item">
                            <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#features-tab-1">
                                <h4>Tailored Support</h4>
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-2">
                                <h4>24/7 Global Coverage</h4>
                            </a>

                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-3">
                                <h4>Scalable Solutions</h4>
                            </a>
                        </li>

                    </ul>

                </div>

                <div className="tab-content" data-aos="fade-up" data-aos-delay="200">

                    <div className="tab-pane fade active show" id="features-tab-1">
                        <div className="row">
                            <div
                                className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Tailored Support</h3>
                                <ul>
                                    <li><i className="bi bi-check2-all"></i> <span>Our solutions are customized to meet the specific needs of your business.</span>
                                    </li>
                                    <li><i className="bi bi-check2-all"></i> <span>We train our staff to work seamlessly with your tools, processes, and workflows, ensuring unparalleled service and smooth integration with your operations.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="static/img/features-illustration-1.webp" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="features-tab-2">
                        <div className="row">
                            <div
                                className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>24/7 Global Coverage</h3>
                                <ul>
                                    <li><i className="bi bi-check2-all"></i> <span>With a team of over 12,000 experts across the globe, we provide round-the-clock support to keep your systems running at all times.</span>
                                    </li>
                                    <li><i className="bi bi-check2-all"></i> <span>No matter the time zone, we’re here to help.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="static/img/features-illustration-2.webp" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="features-tab-3">
                        <div className="row">
                            <div
                                className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Scalable Solutions</h3>
                                <ul>
                                    <li><i className="bi bi-check2-all"></i> <span>Whether your business is scaling up or facing seasonal demands, our services can expand or contract as needed.</span>
                                    </li>
                                    <li><i className="bi bi-check2-all"></i> <span>Our flexible model ensures you only pay for what you need, when you need it.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="static/img/features-illustration-3.webp" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
};


export default FeaturesSection;
