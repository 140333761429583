

import {useDispatch, useSelector} from "react-redux";
import {setEmail} from "../../redux/slices/formDataSlice";


const EmailInput = () => {
    const dispatch = useDispatch();
    const {email} = useSelector((state) => state.formData);
    return (
        <div className="col-md-6 ">
            <input
                value={email}
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required
                onChange={(e) => {
                    dispatch(setEmail(e.target.value))
                }}
            />
        </div>
    );
};


export default EmailInput;
