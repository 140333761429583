

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {uploadApi} from "./api/upload";
import formDataSlice from "./slices/formDataSlice";


export const store = configureStore({
    reducer: {
        [uploadApi.reducerPath]: uploadApi.reducer,
        formData: formDataSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}).concat(uploadApi.middleware),
})


setupListeners(store.dispatch);
