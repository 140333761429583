

import HeroSection from "../components/Sections/HeroSection";
import AboutSection from "../components/Sections/AboutSection";
import FeaturesSection from "../components/Sections/FeaturesSection";
import FeaturesCardSection from "../components/Sections/FeaturesCardSection";
import CallToActionSection from "../components/Sections/CallToActionSection";
import ClientsSection from "../components/Sections/ClientsSection";
import Testimonials from "../components/Sections/Testimonials";
import WantToWorkSection from "../components/Sections/WantToWorkSection";
import ContactSection from "../components/Sections/ContactSection";


const HomePage = () => {
    return (
        <>
            <HeroSection/>
            <AboutSection/>
            <FeaturesSection/>
            <FeaturesCardSection/>
            <CallToActionSection/>
            <ClientsSection/>
            <Testimonials/>
            <WantToWorkSection/>
            <ContactSection/>
        </>
    );
};


export default HomePage;
